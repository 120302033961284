:export {
  navBarHeight: 64px;
  navBarHeightOnPhone: 56px;
  footerHeight: 500px;
}

.navbar-container {
  height: 64px;
  width: calc(100% - 100px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #101010;
}

@media (max-width: 1200px) {
  .navbar-container {
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
  }
}

.navbar-container .icon-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}

@media (max-width: 700px) {
  .navbar-container .icon-container {
    padding-left: 10px;
  }
}

.navbar-container .icon-container .icon {
  height: 90%;
}

.navbar-container .icon-container .name-icon {
  font-family: "Roboto Condensed", "HelveticaNeue-CondensedBold", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  height: 50%;
}

@media (max-width: 321px) {
  .navbar-container .icon-container .name-icon {
    display: none;
  }
}

@media (min-width: 321px) and (max-width: 700px) {
  .navbar-container .icon-container .name-icon {
    font-size: 1.5rem;
  }
}

.navbar-container .nav-items-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

@media (max-width: 700px) {
  .navbar-container .nav-items-container {
    padding-right: 10px;
  }
}

@media (max-width: 1200px) {
  .navbar-container .nav-items-container .nav-items-normal {
    display: none;
  }
}

.navbar-container .nav-items-container .nav-items-drop-down {
  display: none;
}

@media (max-width: 1200px) {
  .navbar-container .nav-items-container .nav-items-drop-down {
    display: block;
  }
}

.navbar-container2 {
  position: fixed;
  top: 0;
  left: 220px;
  right: 0;
  height: 40px;
  margin-top: 64px;
  background-color: #f7cd42;
  color: black;
  -webkit-box-shadow: 0 6px 6px rgba(0, 0, 0, 0.07);
          box-shadow: 0 6px 6px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  z-index: 100;
}

.navbar-container2 p {
  letter-spacing: 0.5px;
}
