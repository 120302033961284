:export {
  navBarHeight: 64px;
  navBarHeightOnPhone: 56px;
  footerHeight: 500px;
}

.blog-1-image-2 {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 300px;
  height: 180px;
  min-width: 300px;
  min-height: 180px;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .blog-1-image-2 {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 700px) {
  .blog-1-image-2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.blog-1-image-3 {
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
  width: 300px;
  height: 180px;
  min-width: 300px;
  min-height: 180px;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .blog-1-image-3 {
    margin-left: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 700px) {
  .blog-1-image-3 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.blog-1-image-4 {
  margin: 20px 0px;
  width: 100%;
}

.blog-1-image-5 {
  margin: 20px 0px;
  width: 100%;
}
