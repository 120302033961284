:export {
  navBarHeight: 64px;
  navBarHeightOnPhone: 56px;
  footerHeight: 500px;
}

.placeholder-component {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.placeholder-component.is-with-drawer {
  margin-left: 220px;
}
