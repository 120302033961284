:export {
  navBarHeight: 64px;
  navBarHeightOnPhone: 56px;
  footerHeight: 500px;
}

.button-container-1 {
  position: relative;
  padding: 10px 20px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #101010;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.button-container-1:hover {
  background-color: #2bb673;
  cursor: pointer;
}
