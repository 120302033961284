:export {
  navBarHeight: 64px;
  navBarHeightOnPhone: 56px;
  footerHeight: 500px;
}

.footer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #101010;
}

.footer-container .optimx-icon {
  height: 100px;
}

@media (max-width: 321px) {
  .footer-container .optimx-icon {
    height: 80px;
  }
}

@media (min-width: 321px) and (max-width: 700px) {
  .footer-container .optimx-icon {
    height: 80px;
  }
}

.footer-container .stripe-badge {
  margin-top: 50px;
  width: 80%;
  max-width: 500px;
}

.footer-container .footer-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}
