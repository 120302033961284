.button-container-2 {
  position: relative;
  padding: 10px 10px;
  text-decoration: none;
  color: white;
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.button-container-2::before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  width: 0%;
  -webkit-transition: 300ms;
  transition: 300ms;
  height: 2px;
  position: absolute;
  background-color: white;
}

.button-container-2:hover {
  cursor: pointer;
}

.button-container-2:hover::before {
  width: 100%;
}
