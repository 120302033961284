:export {
  navBarHeight: 64px;
  navBarHeightOnPhone: 56px;
  footerHeight: 500px;
}

.button-container-3 {
  position: relative;
  padding: 10px 20px;
  text-transform: capitalize;
  text-decoration: none;
  color: white;
  letter-spacing: 1px;
  font-size: 1.15rem;
  font-weight: 800;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  border: none;
  outline: none;
  background-color: #2bb673;
  border-radius: 10px;
  border: 2px solid black;
}

.button-container-3::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: -1;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transform: scale(0);
          transform: scale(0);
  border-radius: 5px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
}

.button-container-3::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: -1;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.button-container-3:hover {
  cursor: pointer;
}

.button-container-3:hover::before {
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.button-container-3:hover::after {
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: scale(0);
          transform: scale(0);
}
