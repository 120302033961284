:export {
  navBarHeight: 64px;
  navBarHeightOnPhone: 56px;
  footerHeight: 500px;
}

.side-drawer .MuiPaper-root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 220px;
  z-index: 100;
  margin-top: 64px;
  height: 100%;
  background-color: #101010;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.side-drawer .MuiPaper-root::-webkit-scrollbar {
  display: none;
}

.side-drawer .side-drawer-team-logo {
  width: 50%;
}

.side-drawer .side-drawer-team-website-button {
  background-color: #2bb673;
  text-decoration: none;
}

.side-drawer .side-drawer-nav-items .side-drawer-accordian-nav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  color: white;
  margin: 0;
  border-bottom-style: solid;
  border-color: #303030;
  border-width: 1px;
}

.side-drawer .side-drawer-nav-items .side-drawer-accordian-nav-item .side-drawer-accordian-nav-item-summary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 50px;
  color: white;
  margin: 0;
  padding: 0;
}

.side-drawer .side-drawer-nav-items .side-drawer-accordian-nav-item .side-drawer-accordian-nav-item-summary .MuiAccordionSummary-expandIcon {
  color: white;
}

.side-drawer .side-drawer-nav-items .side-drawer-accordian-nav-item .side-drawer-accordian-nav-item-summary p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 20px;
}

.side-drawer .side-drawer-nav-items .side-drawer-accordian-nav-item .side-drawer-accordian-nav-item-summary:hover {
  background-color: #404040;
}

.side-drawer .side-drawer-nav-items .side-drawer-accordian-nav-item .side-drawer-accordian-nav-item-summary-active {
  background-color: white !important;
  color: #101010;
}

.side-drawer .side-drawer-nav-items .side-drawer-accordian-nav-item .Mui-expanded {
  min-height: 50px;
}

.side-drawer .side-drawer-nav-items .side-drawer-accordian-nav-item .MuiCollapse-root {
  width: 100%;
}

.side-drawer .side-drawer-nav-items .side-drawer-accordian-nav-item .MuiCollapse-root .side-drawer-accordian-nav-item-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100% !important;
  padding: 0;
  background-color: #303030;
}

.side-drawer .side-drawer-nav-items .side-drawer-accordian-nav-item .MuiCollapse-root .side-drawer-accordian-nav-item-details .side-drawer-nav-item {
  height: 40px;
  border-bottom-style: none;
}

.side-drawer .side-drawer-nav-items .side-drawer-accordian-nav-item .MuiCollapse-root .side-drawer-accordian-nav-item-details .side-drawer-nav-item p {
  padding-left: 65px;
}

.side-drawer .side-drawer-nav-items .side-drawer-nav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 50px;
  color: white;
  margin: 0;
  border-bottom-style: solid;
  border-color: #303030;
  border-width: 1px;
}

.side-drawer .side-drawer-nav-items .side-drawer-nav-item.side-drawer-nav-item-green {
  background-color: #2bb673;
}

.side-drawer .side-drawer-nav-items .side-drawer-nav-item.side-drawer-nav-item-green:hover {
  background-color: #4bd693;
}

.side-drawer .side-drawer-nav-items .side-drawer-nav-item p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 20px;
}

.side-drawer .side-drawer-nav-items .side-drawer-nav-item:hover {
  background-color: #404040;
}

.side-drawer .side-drawer-nav-items .side-drawer-nav-item-active {
  background-color: white !important;
  color: #101010;
}

.side-drawer .side-drawer-nav-items .side-drawer-nav-item-active:hover {
  background-color: white;
  color: #101010;
}

.side-drawer .side-drawer-optimx {
  margin-bottom: 564px;
}

.side-drawer .side-drawer-optimx .side-drawer-optimx-logo {
  width: 50%;
}

.side-drawer .side-drawer-optimx .side-drawer-optimx-text {
  font-family: "Roboto Condensed", "HelveticaNeue-CondensedBold", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
}
